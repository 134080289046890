import { Link } from "gatsby"
import React, { useState } from "react"
import { Button } from "react-bootstrap"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const tennessee = require("../../images/tennessee.png")
const austinLogo = require("../../images/austin.png")
const kaistLogo = require("../../images/kaist.png")
const washingtonLogo = require("../../images/washington.png")
const floridaLogo = require("../../images/florida.png")

const data = require("../../images/data_academia.png")
const sciML = require("../../images/sciML_academia.png")
const SS = require("../../images/SS_academia.png")
const hardware = require("../../images/hardware_academia.png")
const rishabh = require("../../images/rishabh.jpg")
const travis = require("../../images/travis.jpg")
const stanley = require("../../images/stanley.jpg")
const neel = require("../../images/neel.jpg")

const AcademicPage = () => {
  const [hover, setHover] = useState("")

  return (
    <Layout>
      <SEO title="Academic Collaborators" description="" lang="en" meta={[]} />
      <div className="hero-wrapper secondary academic">
        <h1>Collaborators In Academia</h1>

        <h3 className="text-center mb-5">
          Academic institutions who help support the Flapmax mission.
        </h3>
        <a href="https://university.flapmax.com/educator">
          <Button size="lg" className="hero-button primary">
            Collaborate with Us
          </Button>
        </a>
      </div>

      {/* Logo Listings */}
      <section className="partner-section bg-darkened">
        <div className="container">
          <div className="row">
            <div className="col-md partner-block alt-bg">
              <img src={tennessee} alt="" />
            </div>
            <div className="col-md partner-block alt-bg">
              <img src={washingtonLogo} alt="" />
            </div>
            <div className="col-md partner-block alt-bg">
              <img src={austinLogo} alt="" />
            </div>
            <div className="col-md partner-block alt-bg">
              <img src={kaistLogo} alt="" />
            </div>
            <div className="col-md partner-block alt-bg">
              <img src={floridaLogo} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* 2021 Fellows Photos */}
      <section>
        <div className="container">
          <h2 className="text-center mb-5">
            Meet The 2021 Flapmax AI Research Fellows
          </h2>
          <div className="card mw-600 grid two">
            <a href="https://university.flapmax.com/student" target="_blank">
              <img className="img-fluid hover" src={rishabh} />
            </a>
            <a href="https://university.flapmax.com/student" target="_blank">
              <img className="img-fluid hover" alt="" src={travis} />
            </a>

            <a href="https://university.flapmax.com/student" target="_blank">
              <img className="img-fluid hover" alt="" src={stanley} />
            </a>
            <a href="https://university.flapmax.com/student" target="_blank">
              <img className="img-fluid hover" alt="" src={neel} />
            </a>
          </div>
        </div>
      </section>

      {/* Flapmax Seeks To Further Partner */}
      <section className="bg-darkened">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <h2 className="mb-3 text-center">
                Flapmax seeks to further partner with Academia
              </h2>
              <p className="text-center">
                At Flapmax we have a large interest in pushing the limits of
                innovation and a respect for the researchers that help make it
                possible. Flapmax is looking to partner with the brightest minds
                in tech to improve our innovations and create new ones in four
                key areas.
              </p>
            </div>
          </div>

          <div className="row my-5 d-flex align-items-start">
            <div className="d-flex justify-content-center">
              <h2 className="text-center underline mb-5">Focus Areas</h2>
            </div>
            <button
              className="col-lg-3 focus-card hover "
              onClick={() => setHover("Data")}
              onMouseEnter={() => setHover("Data")}
              onMouseLeave={() => setHover("")}
            >
              <div
                className="img-header"
                style={{ backgroundImage: `url(${data})` }}
              >
                <h3 className="text-center">Data</h3>
              </div>
              <div
                className={`${
                  hover === "Data" ? "detail-tab show" : "detail-tab"
                }`}
              >
                <ul>
                  <li>Data Management</li>
                  <li>Graph Analytics</li>
                  <li>Data Science</li>
                  <li>Data Mining</li>
                </ul>
              </div>
            </button>
            <button
              className="col-lg-3 focus-card"
              onClick={() => setHover("SciML")}
              onMouseEnter={() => setHover("SciML")}
              onMouseLeave={() => setHover("")}
            >
              <div
                className="img-header"
                style={{ backgroundImage: `url(${sciML})` }}
              >
                <h3 className="text-center">Scientific ML Applications</h3>
              </div>
              <div
                className={`${
                  hover === "SciML" ? "detail-tab show" : "detail-tab"
                }`}
              >
                <ul>
                  <li>Computational Biology</li>
                  <li>Weather &amp; Climate</li>
                  <li>Drug Discovery</li>
                  <li>Bioinformatics</li>
                </ul>
              </div>
            </button>
            <button
              className="col-lg-3 focus-card"
              onClick={() => setHover("SS")}
              onMouseEnter={() => setHover("SS")}
              onMouseLeave={() => setHover("")}
            >
              <div
                className="img-header"
                style={{ backgroundImage: `url(${SS})` }}
              >
                <h3 className="text-center">System Software</h3>
              </div>
              <div
                className={`${
                  hover === "SS" ? "detail-tab show" : "detail-tab"
                }`}
              >
                <ul>
                  <li>Cloud Management</li>
                  <li>Edge Processing</li>
                  <li>Sensors &amp; IoT</li>
                  <li>Storage</li>
                </ul>
              </div>
            </button>
            <button
              className="col-lg-3 focus-card"
              onClick={() => setHover("Hardware")}
              onMouseEnter={() => setHover("Hardware")}
              onMouseLeave={() => setHover("")}
            >
              <div
                className="img-header"
                style={{ backgroundImage: `url(${hardware})` }}
              >
                <h3 className="text-center">Hardware</h3>
              </div>
              <div
                className={`${
                  hover === "Hardware" ? "detail-tab show" : "detail-tab"
                }`}
              >
                <ul>
                  <li>Reconfigurable Computing</li>
                  <li>Hardware Acceleration</li>
                  <li>System-on-Chip</li>
                  <li>5G Platforms</li>
                </ul>
              </div>
            </button>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row d-flex justify-content-around ">
            <div className="col-lg-5 my-3 card blue">
              <h3>Our Partners in Tech</h3>
              <p>
                We're partnering up with big names in tech because we want to
                make even bigger things happen.
              </p>
              <div>
                <Link to="/partners/industry">
                  <Button>Learn More</Button>
                </Link>
              </div>
            </div>

            <div className="col-lg-5 my-3 card dark">
              <h3>Flapmax University Alliance</h3>
              <p>
                Getting support from academia is a crucial step for Flapmax to
                move forward in the AI &amp; data sphere.
              </p>
              <div>
                <a href="https://university.flapmax.com" target="_blank">
                  <Button>Learn More</Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AcademicPage
